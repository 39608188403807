let instance = null;

export default class StateStore {
    constructor() {
        if (instance) {
            return instance;
        }
        instance = this;

        this.states = {};
        this.callbacks = {};
    }

    setState(name, state) {
        this.states[name] = state;
        this.notify(name);
    }

    getState(name) {
        return this.states[name];
    }

    subscribe(name, callback) {
        if (!this.callbacks[name]) {
            this.callbacks[name] = [];
        }

        this.callbacks[name].push(callback);
    }

    unsubscribe(name, callback) {
        if (this.callbacks[name]) {
            this.callbacks[name] = this.callbacks[name].filter(cb => cb !== callback);
        }
    }

    notify(name) {
        if (this.callbacks[name]) {
            for (const cb of this.callbacks[name]) {
                cb(this.states[name]);
            }
        }
    }
}
